<template>
  <div>
    <div class="grid grid-cols-8 gap-12 md:gap-6 pt-3">
      <h1 class="col-span-8" v-html="selectedProduct.title"/>
      <div class="col-span-8 md:col-span-5">
        <template v-if="selectedProduct.images.length === 1">
          <image-block :data="selectedProduct"/>
        </template>
        <template v-else>
          <carousel-block :data="selectedProduct"/>
        </template>
      </div>
      <section class="col-span-8 md:col-span-3">
        <text-block :data="selectedProduct.description"/>
        <button class="bg-black text-white rounded-full outline-none px-2" @click="addProductToCart(selectedProduct)">
          Add to cart
        </button>
        <span class="px-2">{{ selectedProduct.unitPrice | currency }}</span>
      </section>
    </div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Product',
  components: {
    ImageBlock: () => import('@/components/blocks/ImageBlock.vue'),
    CarouselBlock: () => import('@/components/blocks/CarouselBlock.vue'),
    TextBlock: () => import('@/components/blocks/TextBlock.vue')
  },
  methods: {
    addProductToCart(product) {
      this.$store.dispatch('addProductToCart', product)
    }
  },
  computed: {
    selectedProduct() {
      const slug = this.$route.params.slug 
      return this.products.filter(product => {
        return product.slug === slug
      })[0]
    },
    products() {
      return this.$store.getters.products
    }
  },
}
</script>

<style scoped>
  .col-span-3 div p {
    @apply hidden;
  }
</style>